<template>
  <div class="tourism-content">
    <BannerSection location="homepage-top"></BannerSection>

    <div class="tourism-container m-t-30">

      <FlightCarousel
        v-if="searched && found_flights && found_flights.length"
        :flights="found_flights">

        <router-link
          :to="{
            name: 'DestinationsFlights',
            params: {destination_codes: filter_in_use('to')},
            query: $route.query
          }"
          slot="router-block"
        >
          <p class="weight-400 color-primary">{{ $t( "FLIGHTS.VIEW_ALL_FLIGHTS" ) }}</p>
        </router-link>

      </FlightCarousel>

      <p v-else class="text-center">
        {{ searched ? $t( "FLIGHTS.NO_FLIGHTS_FOUND" ) : $t( "FLIGHTS.PLEASE_SEARCH" ) }}
      </p>
    </div>

    <loading :active.sync="search_in_process" :can-cancel="false" :is-full-page="true"></loading>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import BannerSection from "@/components/BannerSection";
import FlightCarousel from "@/components/tourism/flight/FlightCarousel";
import FullDetailModal from "@/components/tourism/flight/FullDetailModal.vue";

export default {
  name: "FligntHome",
  components: {
    BannerSection,
    FullDetailModal,
    FlightCarousel,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      search_in_process: (state) => state.flights.search_in_process,
      searched: (state) => state.flights.searched,
    }),
    ...mapGetters('flights', {
      single_cheapest_flights_per_destination: 'single_cheapest_flights_per_destination',
      filter_in_use: 'filter_in_use',
    }),
    found_flights() {
        let found_flights = JSON.parse(JSON.stringify(this.single_cheapest_flights_per_destination));
        for (let destination in found_flights) {
            if (!found_flights[destination] || !Object.values(found_flights[destination]).length) {
                delete found_flights[destination];
                continue;
            }

            found_flights[destination].destination = destination;
        }

        return Object.values(found_flights);
    }
  },
  methods: {}
}
</script>

<style lang="scss">
@import "../../../assets/scss/tourism.scss";
</style>

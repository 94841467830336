<template>
    <div class="px10">
        <div class="df-c p-relative card-header tourism-category__place" style="background-image: url('/assets/img/berlin2.png')">
            <h3 class="tourism-category__place-name fs23">{{ flight.to.city_name }}</h3>
            <CategoryShare></CategoryShare>
            <CategoryStar></CategoryStar>
        </div>
        <div class="card-body bg-white p-b-5">
            <p class="fs14 text-center">
                {{ $t( "FLIGHTS.FLIGHT_TO" ) }} <b>{{ flight.to.city_name }}, {{ flight.to.country_name }}</b>
            </p>
            <FlightDesData class="m-t-10" :flight="flight">
                <slot name="flight-des-action-part" slot="action-part"></slot>
            </FlightDesData>
            <div class="d-flex flex-column align-items-center">
                <div class="m-t-20 fs14 text-center">
                    {{ $t( "FLIGHTS.STARTING_FROM" ) }}: <b class="fs16"> {{ flight.price.price | formatPrice }}₪</b>
                </div>
                <slot name="router-block"></slot>
                <p class="fs11 m-t-5">
                    * {{ $t( "FLIGHTS.PRICE_SPECIFIC_FLIGHT_SHOWN" ) }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import RateStar from '@/components/RateStar';
    import CategoryShare from '@/components/CategoryShare';
    import CategoryStar from '@/components/tourism/CategoryStar';
    import FlightDesData from '@/components/tourism/FlightDesData';

    export default {
        name: "FligntCarouselItem",
        components: {
            RateStar,
            CategoryShare,
            CategoryStar,
            FlightDesData,
        },
        props: {
            flight: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    .card-header {
        height: 180px;
        background-size: cover;
    }
    .fs16 {
        font-size: 16px !important;
    }
</style>

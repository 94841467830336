<template>
    <div>
        <h4 class="d-flex align-items-center justify-content-between mx10 p5">
            <p slot="carousel-title">{{ $t( "FLIGHTS.FLIGHTS" ) }}</p>
            <slot name="router-block"></slot>
        </h4>

        <VueSlickCarousel
                v-if="flights && Array.isArray(flights) && flights.length"
                v-bind:slidesToShow="itemPerSlide"
                class="cus-slick-carousel tourism-carousel"
                :class="flights && flights.length < 4 ? sliderDirection : ''"
                :rtl="sliderDirection == 'rtl' ? true : false">

            <FlightCarouselItem
                    v-for="(flight, index) in flights"
                    v-if="flight && flight.id"
                    :flight="flight"
                    :key="index + '_' + flight.id">
                <slot name="item-router-block" slot="router-block">

                    <router-link
                            :to="{
                                name: 'DestinationsFlights',
                                params: {destination_codes: (flight.destination || flight.to.city_code)},
                                query: $route.query
                              }"
                            slot="item-router-block"
                    >
                        <button class="primary-btn small-btn fs16 bg-3">
                            {{ $t( "FLIGHTS.LIST_ALL" ) }}
                        </button>
                    </router-link>
                </slot>

                <button
                    class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15"
                    slot="flight-des-action-part"
                    @click="openFullDetailModal(flight)"
                >
                    <img src="/assets/img/icons/info.svg" alt="" class="">
                    {{ $t( "FLIGHTS.FULL_DETAILS" ) }}
                </button>

            </FlightCarouselItem>

        </VueSlickCarousel>

        <FullDetailModal
            :flight="fullDetailFlightData"
            :modalName="fullDetailModalName"
            :closeModalFunction="closeFullDetailModal"
        ></FullDetailModal>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import FlightCarouselItem from '@/components/tourism/flight/FlightCarouselItem';
    import FullDetailModal from "@/components/tourism/flight/FullDetailModal.vue";

    export default {
        name: "FligntCarousel",
        components: {
            FlightCarouselItem,
            FullDetailModal
        },
        props: {
            flights: {
                type: [Array, Object],
                default: null
            }
        },
        data() {
            return {
                fullDetailModalName: 'full-detail-modal',
                fullDetailFlightData: null
            }
        },
        computed: {
            ...mapGetters('flights', {
                filter_in_use: 'filter_in_use',
            }),
        },
        methods: {
            openFullDetailModal(flight) {
                this.fullDetailFlightData = flight;
                this.$modal.show(this.fullDetailModalName);

            },
            closeFullDetailModal() {
                this.$modal.hide(this.fullDetailModalName);
                this.fullDetailFlightData = null;
            },
        },
        mounted() {}
    }
</script>